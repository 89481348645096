// Media Quares
// desktop breakpoints
$breakpoint-1920: 1920px;

// tablet breakpoints
$breakpoint-1060: 1060px;
$breakpoint-980: 980px;
$breakpoint-768: 768px;

// mobile breakpoints
$breakpoint-580: 580px;
$breakpoint-480: 480px;
$breakpoint-375: 375px;

// desktop queries
@mixin max-screen-1920 {
  @media screen and (max-width: ($breakpoint-1920 - 1)) {
    @content;
  }
}
@mixin min-screen-1920 {
  @media screen and (min-width: $breakpoint-1920) {
    @content;
  }
}

// tablet queries
@mixin max-screen-1060 {
  @media screen and (max-width: ($breakpoint-1060 - 1)) {
    @content;
  }
}
@mixin min-screen-1060 {
  @media screen and (min-width: $breakpoint-1060) {
    @content;
  }
}
@mixin max-screen-980 {
  @media screen and (max-width: ($breakpoint-980 - 1)) {
    @content;
  }
}
@mixin min-screen-980 {
  @media screen and (min-width: $breakpoint-980) {
    @content;
  }
}
@mixin max-screen-768 {
  @media screen and (max-width: ($breakpoint-768 - 1)) {
    @content;
  }
}

@mixin min-screen-768 {
  @media screen and (min-width: $breakpoint-768) {
    @content;
  }
}

// mobile queries
@mixin max-screen-580 {
  @media screen and (max-width: ($breakpoint-580 - 1)) {
    @content;
  }
}
@mixin min-screen-580 {
  @media screen and (min-width: $breakpoint-580) {
    @content;
  }
}
@mixin max-screen-480 {
  @media screen and (max-width: ($breakpoint-480 - 1)) {
    @content;
  }
}
@mixin min-screen-480 {
  @media screen and (min-width: $breakpoint-480) {
    @content;
  }
}

@mixin max-screen-375 {
  @media screen and (max-width: ($breakpoint-375 - 1)) {
    @content;
  }
}
@mixin min-screen-375 {
  @media screen and (min-width: $breakpoint-375) {
    @content;
  }
}
