@import '../../../styles/mixins.scss';

.errorBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
  &__img {
    position: relative;
    width: 151px;
    height: 151px;
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;
    background: rgba(165, 126, 52, 0.55);

    @include max-screen-768 {
      margin-right: 15px;
    }

    @include max-screen-580 {
      border-radius: 0;
      width: 100%;
      height: 85px;
      margin: 0 0 20px 0;
    }

    &:before {
      content: '';
      position: absolute;
      width: 220px;
      height: 121px;
      left: 89%;
      bottom: 0;
      transform: translateX(-50%);
      background: url('/images/book-404.svg') no-repeat 0 0;
      background-size: cover;

      @include max-screen-580 {
        width: 200px;
        height: 110px;
        left: 62%;
        top: 9px;
      }
    }
  }
  &__desk {
    flex: 1;
  }
  &__title {
    font-size: 24px;
    font-family: Arial;
    margin-bottom: 10px;
    padding: 0;
    font-weight: bold;
    color: #5c3e0a;

    @include max-screen-580 {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
  &__txt {
    width: 100%;
    max-width: 420px;
    font-size: 12px;
    line-height: 1.4;
    color: #654109;
    margin-bottom: 20px;
  }
}
