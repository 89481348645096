.btnWithArrow {
  display: inline-block;
  position: relative;
  font-size: 14px;
  padding: 9px 26px 11px 15px;
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
  border-radius: 30px;
  text-decoration: none;
  transition: background 0.3s ease;
  border: 1px solid #825d5d;
  background: var(--btn-brown-grad, linear-gradient(180deg, #f0b35d 0%, #ce9354 29%, #a25b47 56.5%, #9e5946 86%, #c47b51 100%));
  z-index: 1;
  display: inline-block;
  box-shadow: 0px 2px 2px 1px rgba(92, 50, 0, 0.25);
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    z-index: -1;
    background: var(--btn-brown-grad, linear-gradient(180deg, #d09542 0%, #c68641 29%, #a25b47 56.5%, #8e4734 86%, #bd7144 100%));
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:after {
    content: '';
    position: absolute;
    right: 12px;
    top: 13px;
    width: 10px;
    height: 10px;
    background: url('/images/arrow-back.svg') no-repeat 0 0;
    background-size: cover;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}
